import MapDraw from 'map/MapDraw';
import { DataObject } from 'map/types/object';
import { returnAnything } from 'utils/common/returnAnything';
import { validMapData } from './common/valid';

/**
 * object 검색
 * @param floorId
 * @param objectId
 * @return {DataObject | undefined} 찾은 object. 존재하지 않는 object일 경우 undefined 반환.
 */
export const findObject = (floorId: string, objectId: string): DataObject | undefined => {
  validMapData();

  return returnAnything<DataObject>(MapDraw.mapData.dataObject.find({ id: objectId, floorId }));
};
