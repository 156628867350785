/* eslint-disable no-console */
import { logConsole, warnConsole } from 'component/common/console/console';
import MapDraw from 'map/MapDraw';
import { RouteOption } from 'map/types/route';
import { MachineCoordinate } from 'types/common/machine.type';
import { Tenant } from 'types/common/tenant.type';
import { findObject } from './object';

// 경로 탐색
export const getRoute = async (routeOption: RouteOption) => {
  const naviResponse = await MapDraw.mapData.getRoute(routeOption);
  return naviResponse;
};

// 경로 탐색
export const findRoute = async (machinePosition: MachineCoordinate, tenant: Tenant | null) => {
  if (!tenant) {
    return;
  }

  logConsole('출발 좌표 :', machinePosition);
  logConsole('도착지 : ', tenant);
  logConsole('도착 poiId :: ', tenant.content.poiId);

  // object 있을 경우 -> objectId 로 길찾기
  if (tenant.content.objectId) {
    // object 정보 탐색
    const findMapObject = findObject(tenant.content.floorId, tenant.content.objectId);

    if (!findMapObject) {
      return warnConsole('object 를 찾지 못했습니다.');
    }

    const routeOption: RouteOption = {
      origin: {
        position: { x: Number(machinePosition.x), y: Number(machinePosition.y) },
        floorId: MapDraw.machine.floor.id,
      },

      // opening 을 타려면 objectId 를 사용해야 함
      destination: {
        // position: { x: Math.abs(destinationPosition.x), y: Math.abs(destinationPosition.y) },
        floorId: tenant.content.floorId,
        objectId: tenant.content.objectId,
      },
      type: ['recommendation', 'elevator', 'escalator', 'stairs'],
      waypoints: [],
    };

    const naviResponse = await getRoute(routeOption);

    logConsole('도착 object: ', findMapObject);
    logConsole('objectId naviResponse : ', naviResponse);

    return naviResponse;
  }

  // object 없을 경우 -> poiId 로 길찾기
  const routeOption: RouteOption = {
    origin: {
      position: { x: Number(machinePosition.x), y: Number(machinePosition.y) },
      floorId: MapDraw.machine.floor.id,
    },
    destination: {
      poiId: tenant.content.poiId,
      floorId: tenant.content.floorId,
    },
    type: ['recommendation', 'elevator', 'escalator', 'stairs'],
    waypoints: [],
  };

  const naviResponse = await getRoute(routeOption);
  logConsole('poiId naviResponse : ', naviResponse);

  return naviResponse;
};
