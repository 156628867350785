import { warnConsole } from 'component/common/console/console';
import { ModalType } from 'component/common/modal/container/ModalContainer';
import { clearAllMarkers, clearNavigation } from 'map/control/common/clear';
import { DataObject, PoiData } from 'map/types/object';
import { PositionStr } from 'map/types/position';
import useMapActionStore from 'stores/action';
import useFacilityStore from 'stores/facility';
import useLanguageStore from 'stores/languages';
import useTenantStore from 'stores/tenant';
import { TenantWithPointId } from 'types/common/tenant.type';
import { sortByLangOrder } from 'utils/sort/lang-order/sortByLangOrder';

const useMultiPoi = () => {
  const tenantStore = useTenantStore();
  const mapActionStore = useMapActionStore();
  const facilityStore = useFacilityStore();
  const langStore = useLanguageStore();

  const handleMultiPoi = (
    objectPosition: PositionStr,
    findMultiPoiObjects: DataObject[],
    openModal: (type: ModalType) => void,
  ) => {
    if (findMultiPoiObjects.length > 1) {
      warnConsole('다중 Poi Objects 가 여러개입니다.');
      return;
    }

    const poiData: PoiData[] = findMultiPoiObjects[0].poiDataArr;
    const poiDataId: string = poiData[0].id;

    const findTenant: TenantWithPointId | undefined = tenantStore.rawTenants.find(
      item => item.content.poiId === poiDataId,
    );
    const excludeFirstPoiArr: PoiData[] = poiData.slice(1);

    if (findTenant) {
      // TODO: 사용하지 않는 테넌트가 여러개일 경우 처리 필요
      if (!findTenant.used) {
        return;
      }

      clearAllMarkers();
      clearNavigation();
      mapActionStore.resetMapActions();
      facilityStore.resetCurrentFacilityId();
      tenantStore.resetCurrentTenant();

      // 첫번째 테넌트 제외한 배열
      const excludeFirstTenantArr: TenantWithPointId[] = tenantStore.rawTenants
        .filter(rawTenant => excludeFirstPoiArr.some((poi: PoiData) => poi.id === rawTenant.content.poiId))
        .sort((a, b) => sortByLangOrder(a.name[langStore.currentLang], b.name[langStore.currentLang]));

      tenantStore.setCurrentTenant(findTenant);
      tenantStore.setCurrentTenantArr([findTenant, ...excludeFirstTenantArr]);

      // 테넌트 목록 팝업 열기
      openModal(ModalType.tenant);

      // TODO: 다중 poi 일 경우 camera focus 할 position 변경해야 함
      // detail 의 position 하면 안됌
      // object 의 position 으로 할 경우 object find 해야 하고
      // poi 로 할 경우 poi id 로 poi position 찾아야 함
      // moveCamera(objectPosition);
    }
  };

  return { handleMultiPoi };
};
export default useMultiPoi;
