/* eslint-disable no-console */
import { ModalType } from 'component/common/modal/container/ModalContainer';
import useMultiContent from 'hooks/map/multi/useMultiContent';
import useMultiPoi from 'hooks/map/multi/useMultiPoi';
import { addBuilding, hideBuilding } from 'map/control/building';
import { clearMap } from 'map/control/common/clear';
import { DataObject } from 'map/types/object';
import { useState } from 'react';
import useMapActionStore from 'stores/action';
import useCategoryGroupStore from 'stores/categoryGroup';
import useFacilityStore from 'stores/facility';
import useFloorStore from 'stores/floor';
import usePointStore from 'stores/point';
import useQrStore from 'stores/qr';
import useTenantStore from 'stores/tenant';
import { CategoryGroupWithTenants } from 'types/common/category.type';
import { Floor } from 'types/common/floor.type';
import { TenantWithPointId } from 'types/common/tenant.type';
import { changeMapPointSetting } from 'utils/map/changeMapSetting';

type Props = {
  openModal: (type: ModalType) => void;
};

// object-click event 처리
const useMapClick = ({ openModal }: Props) => {
  // store
  const tenantStore = useTenantStore();
  const mapActionStore = useMapActionStore();
  const facilityStore = useFacilityStore();
  const pointStore = usePointStore();
  const qrStore = useQrStore();
  const categoryStore = useCategoryGroupStore();
  const floorStore = useFloorStore();

  // hook
  const { handleMultiPoi } = useMultiPoi();
  const { handlePoiContent } = useMultiContent();

  // var
  const [hasObjectClick, setHasObjectClick] = useState(false);

  // 층, 층 목록, 층 이름 변경
  const changeFloorRelatedStates = (pointId: string) => {
    const findFloors: Floor[] | undefined = floorStore.pointFloorsMap?.get(pointId);

    if (findFloors) {
      floorStore.setFloors(findFloors);

      const findMainFloor = findFloors.find(floor => floor.main);

      if (findMainFloor) {
        // 층
        floorStore.setCurrentFloorId(findMainFloor.id);
        // 층 이름
        floorStore.setFloorName(findMainFloor.name);
      }
    }
  };

  // 카테고리
  const changeCategory = (pointId: string) => {
    if (categoryStore.mapFacilityCategoriesMap) {
      const findCategories: CategoryGroupWithTenants | undefined = categoryStore.mapFacilityCategoriesMap.get(pointId);

      if (findCategories) {
        categoryStore.setCategoryList('MAP_FACILITY', findCategories);
      }
    }
  };

  // 포인트
  const changePointAndCategory = (pointId: string) => {
    const findPoint = pointStore.pointsMap?.get(pointId);

    if (findPoint) {
      pointStore.setSelectedPoint(findPoint);
      // 카테고리
      changeCategory(findPoint.id);

      // 카메라 설정 및 센터값 변경
      changeMapPointSetting(findPoint);
    }
  };

  // outdoor map click 시 building mask 를 제거하고 원하는 building 을 그리고 싶다.
  const handleClickOutdoorBuilding = async (objectClickEvent: any) => {
    const findObject: DataObject | undefined = objectClickEvent.detail.find(
      (object: DataObject) => object.refBuildingId,
    );

    if (findObject) {
      const indoorBuildingId: string | null = findObject.refBuildingId;

      let outdoorBuildingId = '';

      // 우리가 가지고 있는 빌딩들의 outdoor 를 찾아서 만약 있다면 outdoorBuildingId 를 저장
      pointStore.pointsMap?.forEach(value => {
        if (value.buildingType === 'OUTDOOR') {
          outdoorBuildingId = value.buildingId;
        }
      });

      if (indoorBuildingId) {
        // 클릭한 빌딩이 outdoor 가 아니라면 outdoor 빌딩을 지우고 클릭한 빌딩을 그려라
        if (outdoorBuildingId !== indoorBuildingId) {
          // 지도 기능
          hideBuilding(outdoorBuildingId);
          await addBuilding(indoorBuildingId);

          // 상태 관리
          const findPoint = pointStore.buildingPointsMap?.get(indoorBuildingId);

          if (findPoint) {
            // 층, 층 목록, 층 이름 변경
            changeFloorRelatedStates(findPoint.id);

            // 포인트, 카테고리
            changePointAndCategory(findPoint.id);
          }
        }
      }
    }
  };

  // 상태 초기화
  const resetState = () => {
    mapActionStore.resetMapActions();
    facilityStore.resetCurrentFacilityId();
    tenantStore.resetCurrentTenant();
    qrStore.setShowNaviQr(false);
  };

  // 지도 클릭 시
  // TODO: object click 시 일어나는 일들 정리해서 함수 분리
  const handleClickObject = (objectClickEvent: any) => {
    clearMap();
    resetState();

    // Poi 가 들어있는 object 를 모두 찾는다
    const findPoiArrObjects: DataObject[] = objectClickEvent.detail.filter(
      (object: DataObject) => object.poiDataArr.length > 0,
    );

    if (findPoiArrObjects.length < 1) {
      return;
    }

    // poiData 가 한 개인 첫번째 object 를 찾는다
    const findObject: DataObject | undefined = findPoiArrObjects.find(object => object.poiDataArr.length <= 1);

    if (findObject) {
      // content 갯수 check
      const findTenants: TenantWithPointId[] | undefined = tenantStore.poiTenantsMap?.get(findObject.poiDataArr[0].id);

      if (findTenants) {
        handlePoiContent(findTenants, openModal, findObject.position);
      }

      return;
    }

    /**
     * 2024 0215
     * ! 다중 poi 고려하지 않기로 함
     * 추후 다중 poi 로직 들어가게 되면 이곳에 작성
     * poi 한개일 경우를 위에서 먼저 처리하고 있기 때문에 위치 변경 시 버그남
     */
    const findMultiPoiObjects: DataObject[] = findPoiArrObjects.filter(object => object.poiDataArr.length > 1);

    if (findMultiPoiObjects) {
      console.clear();
      console.info('다중 poi 임 !');
      console.info(findMultiPoiObjects);
      handleMultiPoi(objectClickEvent.detail[0].position, findPoiArrObjects, openModal);
    }
  };

  const objectClick = async (objectClickEvent: any) => {
    handleClickObject(objectClickEvent);
    await handleClickOutdoorBuilding(objectClickEvent);

    setHasObjectClick(true);
  };

  return {
    handleClickObject,
    handleClickOutdoorBuilding,
    objectClick,
    hasObjectClick,
  };
};

export default useMapClick;
