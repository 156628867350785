/* eslint-disable no-console */
import useFloor from 'hooks/floor/useFloor';
import { addBuilding } from 'map/control/building';
import { clearMyLocation } from 'map/control/common/clear';
import { changeMapFloor } from 'map/control/floor';
import { showLocation } from 'map/control/location';
import { findObject } from 'map/control/object';
import { findPoi } from 'map/control/poi';
import useCategoryGroupStore from 'stores/categoryGroup';
import useFloorStore from 'stores/floor';
import useMachineStore from 'stores/machine';
import usePointStore from 'stores/point';
import useTenantStore from 'stores/tenant';
import { CategoryGroupWithTenants } from 'types/common/category.type';
import { Floor } from 'types/common/floor.type';
import { Point } from 'types/common/point.type';
import { changeMapPointSetting } from 'utils/map/changeMapSetting';

// 위치 확인
const useMapLocation = () => {
  const tenantStore = useTenantStore();
  const floorStore = useFloorStore();
  const pointStore = usePointStore();
  const categoryStore = useCategoryGroupStore();
  const machineStore = useMachineStore();

  const { findFloorNameByBuildingId } = useFloor();

  // 층 목록 변경
  const handleFloors = (pointId: string) => {
    const findFloors: Floor[] | undefined = floorStore.pointFloorsMap?.get(pointId);

    if (findFloors) {
      floorStore.setFloors(findFloors);
    }
  };

  // 선택된 point 변경
  const handleSelectedPoint = (pointId: string) => {
    const findPoint: Point | undefined = pointStore.pointsMap?.get(pointId);

    if (findPoint) {
      pointStore.setSelectedPoint(findPoint);

      // 카메라 설정 및 센터값 변경
      changeMapPointSetting(findPoint);
    }
  };

  // 카테고리 리스트 변경
  const handlePointCategories = (pointId: string) => {
    if (categoryStore.mapFacilityCategoriesMap) {
      const findCategories: CategoryGroupWithTenants | undefined = categoryStore.mapFacilityCategoriesMap.get(pointId);

      if (findCategories) {
        categoryStore.setCategoryList('MAP_FACILITY', findCategories);
      }
    }
  };

  // 층 이름 변경
  const changeFloorName = (floorId: string, buildingId: string) => {
    const floorName = findFloorNameByBuildingId(floorId, buildingId);

    if (floorName) {
      floorStore.setFloorName(floorName);
    }
  };

  // 위치확인
  const triggerLocation = async () => {
    if (!tenantStore.currentTenant) {
      return;
    }

    const { floorId, objectId, buildingId, poiId } = tenantStore.currentTenant.content;
    const { pointId } = tenantStore.currentTenant;

    // 층
    floorStore.setCurrentFloorId(floorId);
    // 층 목록
    handleFloors(pointId);
    // 층 이름
    changeFloorName(floorId, buildingId);

    // GPS 제거
    if (floorId !== machineStore.machine?.floor.id) {
      clearMyLocation();
    }

    const machinePointId = machineStore.machine?.point.id || '';

    // 지도 변경
    if (pointId === machinePointId) {
      await changeMapFloor(floorId);
    } else {
      await addBuilding(buildingId, floorId);
    }

    // 포인트
    handleSelectedPoint(pointId);

    // 카테고리
    handlePointCategories(pointId);

    // objectId 가 있을 경우 -> object position 로 이동
    if (objectId) {
      const object = findObject(floorId, objectId);

      if (object) {
        await showLocation(floorId, object.position);
      }
      return;
    }

    // objectId 가 없을 경우 -> poiId 로 poi position 로 이동
    const poi = findPoi({
      id: poiId || '',
    });

    if (poi) {
      await showLocation(floorId, poi.position);
    }
  };

  return {
    triggerLocation,
  };
};
export default useMapLocation;
