/* eslint-disable no-console */
import { ModalType } from 'component/common/modal/container/ModalContainer';
import useStats from 'hooks/fetch/stats/useStats';
import { moveCamera } from 'map/control/camera';
import { PositionNum } from 'map/types/position';
import useLanguageStore from 'stores/languages';
import useTenantStore from 'stores/tenant';
import { TenantWithPointId } from 'types/common/tenant.type';
import { sortByLangOrder } from 'utils/sort/lang-order/sortByLangOrder';

const useMultiContent = () => {
  const tenantStore = useTenantStore();
  const langStore = useLanguageStore();

  const { postTenantUsage } = useStats();

  // content 한 개 일 경우
  const handleSingleContent = (tenant: TenantWithPointId) => {
    console.info('단일 content !');

    tenantStore.setCurrentTenant(tenant);
  };

  // 다중 contents 일 경우
  const handleMultiContent = (tenants: TenantWithPointId[]) => {
    console.info('다중 content !');

    // 모든 테넌트 오름차순 정렬
    const sortedTenants: TenantWithPointId[] = tenants.sort((a, b) =>
      sortByLangOrder(a.name[langStore.currentLang], b.name[langStore.currentLang]),
    );

    tenantStore.setCurrentTenant(sortedTenants[0]);
    tenantStore.setCurrentTenantArr(sortedTenants);
  };

  // 다중 content, content 처리
  const handlePoiContent = async (
    tenants: TenantWithPointId[],
    openModal: (type: ModalType) => void,
    cameraPosition: PositionNum,
  ) => {
    // 매장 사용량 수집
    await postTenantUsage(tenants[0].id, 'DETAIL_OF_FLOOR');

    if (tenants.length > 1) {
      // content 여러개일 경우
      handleMultiContent(tenants);
    } else {
      // content 한 개일 경우
      handleSingleContent(tenants[0]);
    }

    // 테넌트 팝업 열기
    openModal(ModalType.tenant);

    // 카메라 이동
    moveCamera(cameraPosition);
  };

  return { handlePoiContent };
};
export default useMultiContent;
